.stats-section {
    padding: 40px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .stats-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .stats-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .stat-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 200px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .stat-number {
    font-size: 2rem;
    font-weight: bold;
    color: #4caf50;
    margin-bottom: 10px;
  }
  
  .stat-label {
    font-size: 1rem;
    color: #777;
    margin-bottom: 20px;
  }
  
  .graph-bar {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    position: absolute;
    bottom: 10px;
    left: 0;
    transform-origin: left;
    transform: scaleX(0);
  }
  
  @keyframes grow {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
  