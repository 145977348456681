.home-carousel {
    background-color: #e6ffe6; /* Light green background */
    padding: 40px 0;
    color: #004d00; /* Dark green */
  }
  
  .home-carousel .carousel-caption {
    color: #ffffff;
    background: rgba(0, 77, 0, 0.7); /* Semi-transparent dark green */
    padding: 20px;
    border-radius: 5px;
  }
  
  .home-carousel img {
    
    object-fit: cover;
    border-radius: 10px;
  }
  