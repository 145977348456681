/* ProductDetailPage.css */
.product-detail img {
  max-width: 100%;
  height: auto;
}

.product-detail h1 {
  font-size: 2rem;
  margin-top: 20px;
}

.product-detail p {
  font-size: 1.2rem;
  color: #555;
}
.product-detail {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px;
}

.product-carousel {
  width: 45%; /* Adjust the width as needed */
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.product-info {
  width: 45%; /* Adjust the width as needed */
  padding-left: 20px;
}

.product-info h1 {
  font-size: 2rem;
  color: #333;
}

.product-info p {
  font-size: 1rem;
  color: #555;
}

