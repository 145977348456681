/* ProductPage.css */
.product-page {
    padding: 40px;
    background-color: #f9f9f9;
  }
  
  .page-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 0 10px;
  }
  
  .product-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .product-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 15px;
  }
  
  .product-description {
    font-size: 1rem;
    color: #777;
    margin-top: 10px;
  }
  
  .view-details {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .view-details:hover {
    background-color: #45a049;
  }
  