.about-preview {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
  color: #333;
}

.about-preview h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.intro-text {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #555;
}

.preview-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.preview-card {
  flex: 1;
  max-width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  margin: 10px;
}

.preview-image {
  width: 100%;
  height: auto; /* Ensures proper aspect ratio */
  border-radius: 5px;
  margin-bottom: 15px;
}

.preview-card h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.preview-card p {
  font-size: 0.9rem;
  color: #777;
}

.about-link a {
  display: inline-block;
  margin-top: 20px;
  color: #4CAF50;
  font-weight: bold;
  text-decoration: none;
}

.about-link a:hover {
  text-decoration: underline;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .preview-card {
    max-width: 100%; /* Allows cards to stack */
  }

  .intro-text {
    font-size: 1rem;
  }

  .about-preview h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .about-preview {
    padding: 20px 10px;
  }
  .preview-cards {
    display: grid;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  .preview-card {
    padding: 15px;
  }

  .about-preview h2 {
    font-size: 1.5rem;
  }

  .intro-text {
    font-size: 0.95rem;
  }
}
