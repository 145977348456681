.product-portfolio {
    padding: 40px;
    background-color: #f0f4f0;
    text-align: center;
  }
  
  .product-portfolio h2 {
    font-size: 2rem;
    color: #004d00; /* Dark green */
    margin-bottom: 20px;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .product-card {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    text-align: center;
    width: 100%;
    max-width: 250px;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .product-card h3 {
    font-size: 1.2rem;
    color: #004d00; /* Dark green */
    margin: 10px 0;
  }
  
  .product-card p {
    color: #666666;
    font-size: 0.9rem;
    margin: 0 15px 15px;
  }
  
  .product-card button {
    background-color: #e60000; /* Red button color */
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-bottom: 15px;
    transition: background-color 0.2s;
    border-radius: 5px;
  }
  
  .product-card button:hover {
    background-color: #c30000;
  }
  