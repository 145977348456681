.product-card {
    background-color: #ffffff;
    border: 1px solid #004d00;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 77, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 77, 0, 0.15);
  }
  
  .product-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .product-card h5 {
    color: #004d00;
    padding: 15px;
    margin: 0;
    text-align: center;
    font-size: 1.1rem;
  }
  