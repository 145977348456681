.about-page {
    padding: 50px 20px;
    background-color: #f2fff2; /* Very light green background */
    color: #004d00;
  }
  
  .about-page h1 {
    color: #004d00;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-page p {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .about-page img {
    display: block;
    margin: 30px auto;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 77, 0, 0.2);
  }
  /* Container for About Page */
.about-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Left Column: Image */
.about-image {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  width: 100%;
  max-width: 500px; /* Adjust as necessary */
  border-radius: 8px;
}

/* Right Column: Text Content */
.about-content {
  flex: 1;
  padding: 20px;
  min-width: 300px;
}

.about-content h2 {
  font-size: 2em;
  color: #28a745; /* Matches theme color */
}

.about-content p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #333;
}
.ab2{
  width: 100%;
}
/* About Page Styles */
.about-page {
  padding: 20px;
}

/* Core Values Section */
.core-values {
  margin-top: 40px;
  text-align: center;
}

.core-values h3 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #28a745; /* Match theme color */
}

/* Values Grid */
.values-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Individual Value Card */
.value-card {
  background-color: #f8f9fa; /* Light background for the cards */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
}

.value-card img.value-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.value-card h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #28a745;
}

.value-card p {
  font-size: 0.95em;
  line-height: 1.6;
  color: #555;
}
