/* CompanyDetails.css */
.company-details {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .company-details h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .company-details p {
    font-size: 1.1rem;
    line-height: 1.5;
    color: #333;
  }
  
  .expanded-details p {
    margin-top: 20px;
    font-size: 1.1rem;
    color: #555;
  }
  
  .toggle-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button:hover {
    background-color: #45a049;
  }
  