.footer {
    background-color: #004d00; /* Dark green */
    color: #ffffff;
    text-align: center;
    padding: 20px;
  }
  
  .footer p {
    margin: 0;
    font-size: 0.9rem;
  }
  
  .footer a {
    color: #a0f1a0; /* Light green */
    text-decoration: none;
  }
  
  .footer a:hover {
    color: #ffffff;
  }
  /* Footer styling */
.footer {
  background-color: #28a745; /* Green background for the footer */
  color: #fff; /* White text */
  padding: 40px 0; /* Padding around the footer */
}

.footer .container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .col-md-3 {
  flex: 1;
  padding: 15px;
  min-width: 250px;
}

.footer .footer-logo img {
  width: 220px; /* Adjust logo size */
  height: 150px;
}

.footer .footer-description {
  font-size: 14px;
  margin-top: 10px;
}

.footer .social-icons a {
  color: #fff;
  text-decoration: none;
  margin-right: 15px;
  font-size: 16px;
}

.footer .social-icons a:hover {
  text-decoration: underline;
}

.footer .product-list {
  list-style: none;
  padding: 0;
}

.footer .product-list li {
  margin-bottom: 10px;
}

.footer .product-list a {
  color: #fff;
  text-decoration: none;
}

.footer .product-list a:hover {
  text-decoration: underline;
}

.footer .map iframe {
  width: 100%;
  height: 150px;
  border: 0;
}

.footer .footer-bottom {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
}

.footer .footer-bottom p {
  margin: 0;
}

  